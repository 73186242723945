import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'menuList',
    component: () => import('../views/MenuList.vue')
  },
  {
    path: '/tablet/',
    name: 'tabMenuList',
    component: () => import('../views/MenuList.vue')
  },
  {
    path: '/info/setData',
    name: 'setData',
    component: () => import('../views/info/setData.vue')
  },
  {
    path: '/monitor/:group/login',
    name: 'login',
    component: () => import('../views/monitor/login.vue')
  },
  {
    path: '/monitor/:group/selectInfo',
    name: 'selectInfo',
    component: () => import('../views/monitor/selectInfo.vue')
  },
  {
    path: '/monitor/:group/printList',
    name: 'monitorPrint',
    component: () => import('../views/monitor/printList.vue')
    // ,meta: { reload: true }
  },
  {
    path: '/monitor/:group/printReplace',
    name: 'monitorPrintReplace',
    component: () => import('../views/monitor/monitorPrintReplace.vue')
    // ,meta: { reload: true }
  },
  {
    path: '/monitor/:group/enterStatus',
    name: 'enterStatus',
    component: () => import('../views/monitor/enterStatus.vue')
  },
  {
    path: '/camping/index',
    name: 'camping',
    component: () => import('../views/user/index.vue')
  },
  {
    path: '/camping/agreement',
    name: 'agreement',
    component: () => import('../views/user/agreement.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
