<!--
  # EDIT (2022-12) css 분리작업(로딩시 css 적용이 안되서 안보이도록 처리)
-->
<template>
  <component :is="componentLoader"></component>
</template>

<script>
import { defineAsyncComponent, computed } from 'vue'
import { useStore } from 'vuex'

export default {
  name: 'componentLoader',
  setup () {
    // # EDIT (2022-12) css 분리작업
    return {
      componentLoader: computed(() => {
        const store = useStore()

        if (store.state.groupCode === undefined || store.state.groupCode === '') {
          // console.log('@/components/base')
          // return defineAsyncComponent(() => import('@/components/base'))
          return null
        } else {
          console.log('@/components/' + (((store.state.groupCode === undefined || store.state.groupCode === '')) ? 'base' : store.state.groupCode))
          return defineAsyncComponent(() => import('@/components/group/' + (((store.state.groupCode === undefined || store.state.groupCode === '')) ? 'base' : store.state.groupCode)))
        }
      })
    }
  }
}
</script>
