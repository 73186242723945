<template>
  <component-loader></component-loader>
  <router-view/>
</template>
<script>
import componentLoader from '@/components/loader/componentLoader'

export default {
  name: 'mainApp',
  components: {
    componentLoader
  }
}
</script>
<style scoped="">
/* # EDIT (2022-12) css 분리작업(로딩시 css 적용이 안되서 안보이도록 처리) */
* {
  display: none;
  background-color: #fff;
}
/*
@import './assets/css/style.css'
@import './assets/css/xeicon.css'
@import './assets/css/swiper-bundle.css'
@import './assets/css/common.css'
*/
</style>
