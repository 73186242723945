import { createApp } from 'vue'
// import { createLogger } from 'vue-logger-plugin'
import App from './App.vue'
import router from './router'
import store from './store'
import globals from './commonGlobal/global.js' // [global]
import logger from './components/logger'

const app = createApp(App)
app.use(store)
app.use(router)
app.use(globals)
app.use(logger)
app.mount('#body')
// app.config.globalProperties.$lockerViewTime = 5
// app.config.globalProperties.$device = ''
