import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'

export default createStore({
  plugins: [
    createPersistedState({
      storage: window.sessionStorage // store를 session storage 에 유지
      // paths: ["noticeStore"]
    })
  ],
  state: {
    groupCode: '',
    viewType: '',
    siteName: '',
    siteLogo: '',
    siteLogoText: '',
    device: '',
    secretKey: '',
    tabletId: '',
    q1: '',
    q2: '',
    monitorMenu: '',
    monitorListType: ''
  },
  getters: {
    getGroupCode () {
      return sessionStorage.getItem('groupCode')
      // return this.state.groupCode
    },
    getViewType () {
      return sessionStorage.getItem('viewType')
    },
    getSiteName () {
      return sessionStorage.getItem('siteName')
    },
    getSiteLogo () {
      return sessionStorage.getItem('siteLogo')
    },
    getSiteLogoText () {
      return sessionStorage.getItem('siteLogoText')
    },
    getDevice () {
      return sessionStorage.getItem('device')
    },
    getSecretKey () {
      return sessionStorage.getItem('secretKey')
    },
    getTabletId () {
      return sessionStorage.getItem('tabletId')
    },
    getMonitorQ1 () {
      return sessionStorage.getItem('q1')
    },
    getMonitorQ2 () {
      return sessionStorage.getItem('q2')
    },
    getMonitorMenu () {
      return sessionStorage.getItem('monitorMenu')
    },
    getMonitorListType () {
      return sessionStorage.getItem('monitorListType')
    }
  },
  mutations: {
    setGroupCode (state, groupCode) {
      state.groupCode = groupCode
    },
    setViewType (state, viewType) {
      state.viewType = viewType
    },
    setSiteName (state, siteName) {
      state.siteName = siteName
    },
    setSiteLogo (state, siteLogo) {
      state.siteLogo = siteLogo
    },
    setSiteLogoText (state, siteLogoText) {
      state.siteLogoText = siteLogoText
    },
    setDevice (state, device) {
      state.device = device
    },
    setSecretKey (state, secretKey) {
      state.secretKey = secretKey
    },
    setTabletId (state, tabletId) {
      state.tabletId = tabletId
    },
    setMonitorQ1 (state, q1) {
      state.q1 = q1
    },
    setMonitorQ2 (state, q2) {
      state.q2 = q2
    },
    setMonitorMenu (state, monitorMenu) {
      state.monitorMenu = monitorMenu
    },
    setMonitorListType (state, monitorListType) {
      state.monitorListType = monitorListType
    }
  },
  actions: { },
  modules: { }
})
