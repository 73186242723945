// [global > global.js 설명]
// 1. 모든 컴포넌트가 공유할 수 있는 글로벌 영역 입니다
// [설정 수행 실시]
let _device = ''
let _groupCode = ''
let _tabletId = ''
// let _secretKey = 'de4ba45e792046c39966d981fd1ddd2d'
let _secretKey = ''
let _monitorLocationCode = ''
let _monitorLocationName = ''
let _monitorGenderCode = ''
let _monitorGenderName = ''
let _monitorLocationList = []
const _lockerViewTime = 10
// const _secreyKey = 'de4ba45e792046c39966d981fd1ddd2d'
// const _userId = 'mirihaeTab@gmail.com'
// const _userPass = 'tablet12#$'

export default {
  install (Vue) {
    // Setter
    Vue.config.globalProperties.$setDevice = function (device) {
      _device = device
    }
    Vue.config.globalProperties.$setGroupCode = function (groupCode) {
      _groupCode = groupCode
    }
    Vue.config.globalProperties.$setSecretKey = function (secretKey) {
      _secretKey = secretKey
    }
    Vue.config.globalProperties.$setTabletId = function (tabletId) {
      _tabletId = tabletId
    }
    Vue.config.globalProperties.$setMonitorLocationList = function (list) {
      _monitorLocationList = list
    }
    Vue.config.globalProperties.$setMonitorLocationCode = function (code) {
      _monitorLocationCode = code
    }
    Vue.config.globalProperties.$setMonitorLocationName = function (name) {
      _monitorLocationName = name
    }
    Vue.config.globalProperties.$setMonitorGenderCode = function (code) {
      _monitorGenderCode = code
    }
    Vue.config.globalProperties.$setMonitorGenderName = function (name) {
      _monitorGenderName = name
    }

    // Getter
    Vue.config.globalProperties.$getDevice = function () {
      return _device
    }
    Vue.config.globalProperties.$getGroupCode = function () {
      return _groupCode
    }
    Vue.config.globalProperties.$getSecretKey = function () {
      return _secretKey
    }
    Vue.config.globalProperties.$getTabletId = function () {
      return _tabletId
    }
    Vue.config.globalProperties.$getLockerViewTime = function () {
      return _lockerViewTime
    }
    Vue.config.globalProperties.$getMirihaeSecretKey = function () {
      return _secretKey
    }
    Vue.config.globalProperties.$getMonitorLocationList = function () {
      return _monitorLocationList
    }
    Vue.config.globalProperties.$getMonitorLocationCode = function () {
      return _monitorLocationCode
    }
    Vue.config.globalProperties.$getMonitorLocationName = function () {
      return _monitorLocationName
    }
    Vue.config.globalProperties.$getMonitorGenderCode = function () {
      return _monitorGenderCode
    }
    Vue.config.globalProperties.$getMonitorGenderName = function () {
      return _monitorGenderName
    }
    Vue.config.globalProperties.$getGroupSecretKey = function () {
      if (_groupCode === 'gsmc') {
        return 'de4ba45e792046c39966d981fd1ddd2d'
      }
      if (_groupCode === 'gnsc') {
        return 'de4ba45e792046c39966d981fd1ddd2d'
      }
      if (_groupCode === 'mirihae') {
        return 'bc3135a6b1f94fce9a8b'
      }
      if (_groupCode === 'jamaica') {
        return '1234'
      }
      if (_groupCode === 'yjpg') {
        return '12345'
      }
      if (_groupCode === 'sfarm') {
        return '12345'
      }
      return ''
    }
    Vue.config.globalProperties.$finishWeb = function () {
      try {
        window.Android.finishWeb()
      } catch {}
    }
    Vue.config.globalProperties.$playTTS = function (message) {
      try {
        // alert('TTS호출2')
        window.Android.playTTS(message)
      } catch {}
    }
    // Vue.config.globalProperties.$userId = function () {
    //   return _userId
    // }
    // Vue.config.globalProperties.$userPass = function () {
    //   return _userPass
    // }
  }
}
