// import { createLogger, StringifyObjectsHook, LoggerHook } from 'vue-logger-plugin'
import { createLogger, StringifyObjectsHook } from 'vue-logger-plugin'
import axios from 'axios'

// create logger with options
const logger = createLogger({
  enabled: true,
  consoleEnabled: true,
  level: 'debug', // debug, info, warn, error, log
  callerInfo: true,
  prefixFormat: ({ level, caller }) => (
    caller ? `[${level.toUpperCase()}] [${caller?.fileName.replace('.vue', '')}] [${caller?.functionName}:${caller?.lineNumber}]` : `[${level.toUpperCase()}]`
  ),
  beforeHooks: [
    StringifyObjectsHook
  ],
  afterHooks: [
    {
      run (event) {
        axios.post('/logger/write', { severity: event.level, file: event.caller, data: event.argumentArray })
      }
    }
  ]
})

export default logger
